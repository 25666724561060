import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '@mui/material';
import moment from 'moment';

import Box from '../../../../common/box/Box';
import SingleOption from './SingleOption';
import { DatePicker } from '../../../../common/datepicker';

const ObjectLockingOptions = props => {
  const { t } = useTranslation();
  const { formik, locked, alreadySelectedDate, alreadySelectedMode } = props;
  const { values } = formik;
  const { selectedMode, retainDate } = values;

  const disabledDate = date => {
    if (
      alreadySelectedMode?.toUpperCase() === 'GOVERNANCE' &&
      selectedMode?.toUpperCase() === 'COMPLIANCE'
    ) {
      return moment(date).isSameOrBefore(moment(), 'day');
    }
    if (
      selectedMode?.toUpperCase() === 'COMPLIANCE' &&
      !!alreadySelectedDate?.length
    ) {
      const selectedDate = moment(alreadySelectedDate || retainDate);
      // Disable the selected date and all previous dates
      return (
        moment(date).isSameOrBefore(selectedDate, 'day') ||
        moment(date).isSameOrBefore(moment(), 'day')
      );
    } else {
      // Disable all past dates
      return moment(date).isBefore(moment().add(1, 'day'), 'day');
    }
  };

  const [disableScrollModal, setDisableScrollModal] = useState(false);

  useEffect(() => {
    const element = document.getElementById('applyButton');
    element.scrollIntoView();

    return () => formik.resetForm();
  }, []);

  return (
    <Box>
      <SingleOption
        id={'GOVERNANCE'}
        checked={selectedMode?.toUpperCase() === 'GOVERNANCE'}
        onChange={() => formik.setFieldValue('selectedMode', 'GOVERNANCE')}
        title={t('enableGovernanceMode')}
        description={t('governanceModeDescription')}
      />
      <SingleOption
        id={'COMPLIANCE'}
        checked={selectedMode?.toUpperCase() === 'COMPLIANCE'}
        onChange={() => formik.setFieldValue('selectedMode', 'COMPLIANCE')}
        title={t('enableComplianceMode')}
        description={t('complianceModeDescription')}
      />
      {locked && (
        <SingleOption
          id={'none'}
          checked={selectedMode === null}
          onChange={() => formik.setFieldValue('selectedMode', null)}
          title={t('none')}
          description={t('usedOnlyifObjectLockingExists')}
        />
      )}
      <Modal
        open={disableScrollModal}
        onClose={() => setDisableScrollModal(false)}
        className="transparent_modal"
      >
        <></>
      </Modal>

      <Box mt="27px">
        <DatePicker
          onOpen={() => setDisableScrollModal(true)}
          onClose={() => setDisableScrollModal(false)}
          disabledDate={date => disabledDate(date)}
          cleanable={false}
          name="retainDate"
          formik={formik}
          onChange={e => formik.setFieldValue('retainDate', e)}
          value={retainDate}
          disabled={selectedMode === null}
          label={`${t('retainUntil')}:`}
          placement={'leftEnd'}
        />
      </Box>
    </Box>
  );
};
export default ObjectLockingOptions;
