import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import fromExponential from 'from-exponential';

import Box from '../common/box/Box';
import i18n from '../../i18n';
import Text from '../common/text/Text';
import { colors } from '../constant';
import { renderCellExpand } from '../common/table/TableCellExpand';

const RenderEndTime = props => {
  // eslint-disable-next-line react/prop-types
  const endTime = props?.row?.endTime;
  const dateFormat = 'DD-MMM-YYYY';
  let date = new Date(endTime);
  if (date.getTimezoneOffset() > 0) {
    date = moment(date).subtract(1, 'day');
  }
  return (
    <Box>
      <DateText>{moment(date)?.format(dateFormat)}</DateText>
    </Box>
  );
};

function formatNumber(value) {
  let newValue = fromExponential(value);
  newValue = Number(newValue).toFixed(3);
  return newValue;
}

function formatNumbernotFixed(value) {
  let newValue = fromExponential(value);
  newValue = Number(newValue).toFixed();
  return newValue;
}

export const columns = [
  {
    field: 'endTime',
    headerName: i18n.t('recordDate'),
    minWidth: 120,
    sortable: false,
    renderCell: params => RenderEndTime(params)
  },

  {
    field: 'activeStorage',
    headerName: i18n.t('activeStorageTB'),
    minWidth: 165,
    sortable: false,
    // valueGetter: (params) => formatNumber(params.value).replace(/\B(?=(\d{3})+(?!\d))/g, ","),
    headerAlign: 'right',
    cellClassName: 'row-flex-end',
    renderCell: params =>
      renderCellExpand({
        value: formatNumber(params.row.activeStorage).replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ','
        ),
        width: params,
        color: colors.gray20,
        customClassName: 'row-flex-end'
      })
  },
  {
    field: 'deletedStorage',
    headerName: i18n.t('deletedStorageTB'),
    minWidth: 165,
    sortable: false,
    // valueGetter: (params) => formatNumber(params.value).replace(/\B(?=(\d{3})+(?!\d))/g, ","),
    headerAlign: 'right',
    cellClassName: 'row-flex-end',
    renderCell: params =>
      renderCellExpand({
        value: formatNumber(params.row.deletedStorage).replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ','
        ),
        width: params,
        color: colors.gray20,
        customClassName: 'row-flex-end'
      })
  },
  {
    field: 'activeObjects',
    headerName: i18n.t('activeObjects'),
    minWidth: 125,
    sortable: false,
    headerAlign: 'right',
    cellClassName: 'row-flex-end',
    renderCell: params =>
      renderCellExpand({
        value: formatNumbernotFixed(params.row.activeObjects).replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ','
        ),
        width: params,
        color: colors.gray20,
        customClassName: 'row-flex-end'
      })
  },
  {
    field: 'deletedObjects',
    headerName: i18n.t('deletedObjects'),
    minWidth: 130,
    sortable: false,
    headerAlign: 'right',
    cellClassName: 'row-flex-end',
    renderCell: params =>
      renderCellExpand({
        value: formatNumbernotFixed(params.row.deletedObjects).replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ','
        ),
        width: params,
        color: colors.gray20,
        customClassName: 'row-flex-end'
      })
  },
  {
    field: 'apiCalls',
    headerName: i18n.t('apiCalls'),
    minWidth: 95,
    sortable: false,
    headerAlign: 'right',
    cellClassName: 'row-flex-end',
    renderCell: params =>
      renderCellExpand({
        value: formatNumbernotFixed(params.row.apiCalls).replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ','
        ),
        width: params,
        color: colors.gray20,
        customClassName: 'row-flex-end'
      })
  },
  {
    field: 'egress',
    headerName: i18n.t('egressGB'),
    minWidth: 115,
    sortable: false,
    // valueGetter: (params) => formatNumber(params.value).replace(/\B(?=(\d{3})+(?!\d))/g, ","),
    headerAlign: 'right',
    cellClassName: 'row-flex-end',
    renderCell: params =>
      renderCellExpand({
        value: formatNumber(params.row.egress).replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ','
        ),
        width: params,
        color: colors.gray20,
        customClassName: 'row-flex-end'
      })
  },
  {
    field: 'ingress',
    headerName: i18n.t('ingressGB'),
    minWidth: 110,
    sortable: false,
    // valueGetter: (params) => formatNumber(params.value).replace(/\B(?=(\d{3})+(?!\d))/g, ","),
    headerAlign: 'right',
    cellClassName: 'row-flex-end',
    renderCell: params =>
      renderCellExpand({
        value: formatNumber(params.row.ingress).replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ','
        ),
        width: params,
        color: colors.gray20,
        customClassName: 'row-flex-end'
      })
  },
  {
    field: 'storageWrote',
    headerName: i18n.t('storageWroteTB'),
    minWidth: 165,
    sortable: false,
    // valueGetter: (params) => formatNumber(params.value).replace(/\B(?=(\d{3})+(?!\d))/g, ","),
    headerAlign: 'right',
    cellClassName: 'row-flex-end',
    renderCell: params =>
      renderCellExpand({
        value: formatNumber(params.row.storageWrote).replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ','
        ),
        width: params,
        color: colors.gray20,
        customClassName: 'row-flex-end'
      })
  },
  {
    field: 'storageRead',
    headerName: i18n.t('storageReadTB'),
    minWidth: 175,
    sortable: false,
    // valueGetter: (params) => formatNumber(params.value).replace(/\B(?=(\d{3})+(?!\d))/g, ","),
    headerAlign: 'right',
    headerClassName: 'last-row',
    cellClassName: 'row-flex-end last-row',
    renderCell: params =>
      renderCellExpand({
        value: formatNumber(params.row.storageRead).replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ','
        ),
        width: params,
        color: colors.gray20,
        customClassName: 'row-flex-end'
      })
  }
];

export const DateText = styled(Text)(
  ({ theme }) => `
    color: ${theme.themes?.colorPrimary};
    font-weight: 500;
`
);
