/* eslint-disable no-param-reassign */
export function formatStorageValue(value) {
  let unit = '';
  value = value * 1024 * 1024 * 1024 * 1024;
  let formattedValue = '--';

  if (value === 0) {
    unit = 'B';
    formattedValue = 0;
  }

  if (value >= 1) {
    unit = 'B';
    formattedValue = value.toFixed(0);
  }

  if (value >= 1024) {
    value /= 1024;
    unit = 'KB';
    formattedValue = Math.round(value).toString();
  }

  if (value >= 1024) {
    value /= 1024;
    unit = 'MB';
    formattedValue = Math.round(value).toString();
  }

  if (value >= 1024) {
    value /= 1024;
    unit = 'GB';
    formattedValue = Math.round(value).toString();
  }

  if (value >= 1024) {
    value /= 1024;
    unit = 'TB';
    formattedValue = value.toFixed(3);
  }

  if (value >= 1024) {
    value /= 1024;
    unit = 'PB';
    formattedValue = value.toFixed(3);
  }

  return `${formattedValue} ${unit}`;
}
