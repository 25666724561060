import { createGlobalStyle } from 'styled-components';
import RobotoRegular from '../assets/fonts/Roboto-Regular.ttf';
import RobotoMedium from '../assets/fonts/Roboto-Medium.ttf';
import InterBlack from '../assets/fonts/Inter-Black.ttf';
import InterMedium from '../assets/fonts/Inter-Medium.ttf';
import InterRegular from '../assets/fonts/Inter-Regular.ttf';
import { colors } from './constant';

export default createGlobalStyle`
  @font-face {
    font-family: Inter;
    src: url(${InterBlack}) format('truetype');
    font-weight: 900;
  }

  @font-face {
    font-family: Inter;
    src: url(${InterMedium}) format('truetype');
    font-weight: 500;
  }

  @font-face {
    font-family: Inter;
    src: url(${InterRegular}) format('truetype');
    font-weight: 400;
  }

  @font-face {
    font-family: Roboto;
    src: url(${RobotoRegular}) format('truetype');
    font-weight: 400;
  }

  @font-face {
    font-family: Roboto;
    src: url(${RobotoMedium}) format('truetype');
    font-weight: 500;
  }

  html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  body {
    margin: 60px 0 0 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
    background-color: ${colors.softGray};
  }

  * {
    outline: none;
    // box-sizing: inherit;
  }

  button {
    box-sizing: border-box;
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: inherit;
    line-height: normal;
  }

  ul,
  label {
    margin: 0;
    padding: 0;
  }

  li {
    list-style: none;
  }
  
  a{
    text-decoration: none !important;
    color: ${({ theme }) => theme.themes?.navColor} !important;
  }

  a,
  a:hover {
    color: ${({ theme }) => theme.themes?.navColor};
  }

  p {
    margin: 0;
  }

  button {
    background: none;
    border: none;
    cursor: pointer;
    -webkit-appearance: none;
  }

  button:focus {
    outline: none;
  }

  [type="button"],
  [type="reset"],
  [type="submit"],
  button {
    -webkit-appearance: none;
  }

  textarea {
    resize: none;
  }

  .active {
    color: ${({ theme }) => theme.themes?.navActiveColor} !important;
    font-weight: 500 !important;
    position: relative;

    &:hover {
      color: ${({ theme }) => theme.themes?.navActiveColor} !important;
    }

    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: -19px;
      width: 100%;
      height: 3px;
      background-color: ${({ theme }) => theme.themes?.navActiveColor};
    }
  }

  .active-tabs {
    color: ${({ theme }) => theme.themes?.darkBlue}!important;
    &:hover{
      color: ${({ theme }) => theme.themes?.darkBlue}!important;
    }
    &.active {
      color: ${({ theme }) => theme.themes?.colorPrimary} !important;
      &:after {
        background-color: ${({ theme }) => theme.themes?.colorPrimary};
      }
    }
  }

  .input-wrapper {
    display: flex;
    position: relative !important;

    label {
      position: absolute;
      cursor: text;
      z-index: 2;
      top: 10px;
      left: 10px;
      font-size: 14px;
      font-weight: 400;
      background: #fff;
      padding: 0 0 0 6px;
      color: #788089;
      transition: all .3s ease;
      width: calc(100% - 20px);
    }

    input {
      z-index: 3;

      &:focus {
        z-index: 1;
      }
    }

    input:focus + label, .filledField, .disabledFillField,
    .form-item input:valid + label {
      width: auto;
      font-size: 12px;
      top: -6px;
      z-index: 4;
    }
  }

  .input-wrapper-disabled {
    input{
      background-color: ${colors.softGray};
    }
    .input-label{
      z-index: 3;
      background-color: transparent;
    }
  }

  .input-wrapper.modified {
    input:focus + label, .filledField, .disabledFillField,
    .form-item input:valid + label {
      font-size: 12px;
      top: -8px;
      z-index: 4;
    }
  }

  .dropzone {
    width: 100%;
    min-height: 125px;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    border-width: .09rem;
    border-radius: 4px;
    border-color: ${colors.gray30};
    border-style: dashed;
    background-color: transparent;
    outline: none;
    transition: border .24s ease-in-out;
  }

  .dropzone.dragActive{
    border: 1px solid ${({ theme }) => theme.themes?.colorPrimary};
  }

  .dropzone:focus {
    border-color: #2196f3;
    background: red;
  }

  .dropzone.disabled {
    opacity: 0.6;
  }

  .dropzone.dropzone-empty-view {
    min-height: 198px;
  }

  .verify-wrap {
    margin: 20px 0 40px;
    display: flex;
    justify-content: space-between;
  }

  .input-verify-code {
    z-index: 1;
    width: 50px;
    height: 50px;
    min-width: 50px;
    min-height: 50px;
    padding: 9px;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 500;
    margin-right: 30px;
    border: 1px solid ${colors.gray};
    background-color: ${colors.white};
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      cursor: pointer;
    }

    &--selected {
      border-color: ${({ theme }) => theme.themes?.colorPrimary};
      outline: none;
      color: ${colors.black};
    }
  }

  .input-verify-code-login {
    margin-right: 8px;
  }

  .cloud {
    animation: fadeIn .8s infinite alternate;
  }

  .text-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: initial;
  }


  .files-upload-wrap {
    max-height: 200px;
    overflow-y: auto;
    margin-top: 10px;

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      background-color: ${colors.lightGray};
    }

    &::-webkit-scrollbar {
      width: 8px;
      background-color: #F5F5F5;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .1);
      background-color: ${colors.bodyColor};
    }
  }

  .right-side {
    justify-content: flex-end !important;
    padding-right: 20px !important;
  }

  .color_primary {
    color: ${({ theme }) => theme.themes?.colorPrimary};
  }

  .color_primary path {
    fill: ${({ theme }) => theme.themes?.colorPrimary};
  }

  .line-progress-bar {
    width: 100%;
  }

  .table-cell-trucate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    color: ${({ theme }) => theme.themes?.colorPrimary};
    font-weight: 500;
  }

  input[type="password"]::-ms-reveal,
  input[type="password"]::-ms-clear {
    display: none;
  }

  .no-highlight .MuiDataGrid-cell {
    cursor: initial !important;
  }

  .no-highlight:hover {
    background-color: transparent !important;
    cursor: initial !important;
  }

  .pr-40 {
    padding-right: 40px !important;
  }

  [aria-expanded="true"] {
    z-index: -2;
  }
  #mui-component-select-connectionType{
    z-index: 999 !important;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
  }

  .menu-item-tooltip-span {
    font-size: 14px;
    font-weight: 400;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 162px;
    margin-right: 10px;

    &::after {
      content: '';
      display: block;
    }
  }

  // Date Picker //
  .rs-picker-toggle {
    line-height: 26px;
  }

  .placement-left-end.rs-picker-menu .rs-stack{
    flex-direction: column !important;
  }
  
  .placement-left-end.rs-picker-menu .rs-stack .rs-stack-item{
    width: 100% !important;
  }
  .placement-left-end.rs-picker-menu .rs-stack .rs-stack-item .rs-picker-toolbar {
    display: flex;
    align-items: flex-end !important;
  }
  .placement-left-end.rs-picker-menu .rs-stack .rs-stack-item .rs-picker-toolbar-right{
    display: flex !important;
    justify-content: flex-end !important;
  } 

  .placement-left-end.rs-picker-popup .rs-stack{
    flex-direction: column !important;
  }
  
  .placement-left-end.rs-picker-popup .rs-stack .rs-stack-item{
    width: 100% !important;
  }
  .placement-left-end.rs-picker-popup .rs-stack .rs-stack-item   .rs-picker-toolbar {
    display: flex;
    align-items: flex-end !important;
  }
  .placement-left-end.rs-picker-popup .rs-stack .rs-stack-item .rs-picker-toolbar-right{
    display: flex !important;
    justify-content: flex-end !important;
  } 
  
  .rs-picker-default:not(.rs-picker-disabled) .rs-btn:hover,
  .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:hover,
  .rs-picker-default:not(.rs-picker-disabled) .rs-btn:focus,
  .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:focus,
  .rs-picker-default:not(.rs-picker-disabled) .rs-btn-active,
  .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle-active {
    border-color: #e5e5ea;
    box-shadow: none;
  }

  .rs-picker-daterange-header {
    display: none;
  }

  .rs-picker-has-value .rs-btn .rs-picker-toggle-value,
  .rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
    color: ${colors.bodyColor};
  }

  .rs-picker-default .rs-picker-toggle {
    border-color: ${colors.lightGray};
  }

  .rs-picker-daterange-predefined {
    min-width: 180px;
    padding: 20px;
  }

  .rs-calendar-table-header-row {
    background-color: ${colors.softGray};
  }

  .rs-calendar-table-header-cell-content,
  .rs-btn-xs {
    font-size: 14px;
    color: ${colors.darkBlue};
    font-weight: 500;
  }

  .rs-picker-daterange-menu
  .rs-calendar:first-child {
    border-right: 0;
  }

  .rs-btn-link {
    color: ${colors.bodyColor};
    width: 100%;
    padding: 10px 15px;

    &:hover, &:focus {
      color: ${colors.bodyColor};
      text-decoration: none;
      background-color: ${colors.softGray};
    }
  }

  .rs-picker-daterange-predefined {
    height: 328px;
    border-color: ${colors.softGray};
  }

  .rs-picker-toolbar {
    border-color: ${colors.softGray};
  }

  .rs-picker-menu .rs-calendar .rs-calendar-table-cell-content {
    width: 32px;
    height: 32px;
    line-height: 22px;
  }

  .rs-picker-default .rs-picker-toggle.rs-btn:focus-visible,
  .rs-picker-default .rs-picker-toggle.rs-btn:focus,
  .rs-picker-default .rs-picker-toggle.rs-btn:hover {
    outline: none !important;
    border-color: ${colors.softGray};
  }

  
  .rs-calendar-table-cell-selected .rs-calendar-table-cell-content{
    background-color:${({ theme }) => theme.themes?.colorPrimary} !important;
  }

  .rs-picker-menu .rs-calendar .rs-calendar-table-cell-content:hover{
    background-color:${({ theme }) => theme.themes?.colorPrimary} !important;
    color: white !important;
  }
  .rs-stack-item .rs-btn-primary{
    background-color:${({ theme }) => theme.themes?.colorPrimary} !important;
  }
  .rs-picker-menu .rs-calendar .rs-calendar-table-cell:hover .rs-calendar-table-cell-content {
    background-color:${({ theme }) => theme.themes?.colorPrimary} !important;
    color: white !important;
  }
  
  .rs-calendar-header-title{
    color:${({ theme }) => theme.themes?.colorPrimary} !important;
    &:hover, &:focus, &:active{
      color: ${colors.white} !important;
      background-color:${({ theme }) => theme.themes?.colorPrimary} !important;
    }
  }
  
  .rs-calendar-month-dropdown-year-active{
    color:${({ theme }) => theme.themes?.colorPrimary} !important;
  }
  
  .rs-calendar-month-dropdown-cell-active .rs-calendar-month-dropdown-cell-content{
    background-color: ${({ theme }) => theme.themes?.colorPrimary} !important;
  }
  
  .rs-calendar-table-cell:hover .rs-calendar-month-dropdown-cell-content{
    color: white !important;
    background-color: ${({ theme }) => theme.themes?.colorPrimary} !important;
  }

  .rs-calendar-month-dropdown-cell .rs-calendar-month-dropdown-cell-content:hover{
    color: white !important;
    background-color: ${({ theme }) => theme.themes?.colorPrimary} !important;
  }

  .rs-picker-menu .rs-calendar .rs-calendar-table-cell.rs-calendar-table-cell-disabled:hover
  .rs-calendar-table-cell-content {
    background-color: transparent !important;
    color: #c5c6c7 !important;
  }

  .rs-calendar-month-dropdown-cell.disabled .rs-calendar-month-dropdown-cell-content:hover {
    background-color: transparent !important;
    color: #c5c6c7 !important;
  }

  .rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
    -webkit-box-shadow: inset 0 0 0 1px ${({ theme }) =>
      theme.themes?.colorPrimary};
    -webkit-box-shadow: inset 0 0 0 1px ${({ theme }) =>
      theme.themes?.colorPrimary};
    box-shadow: inset 0 0 0 1px ${({ theme }) => theme.themes?.colorPrimary};
    box-shadow: inset 0 0 0 1px ${({ theme }) => theme.themes?.colorPrimary};
  }

  .details__modal {
    z-index: 6 !important;
  }
  .transparent_modal{
    z-index: 6 !important;
    background-color: transparent !important;
  }
  .transparent_modal .MuiBackdrop-root{
    opacity: 0 !important;
    tranistion: none !important;
  }
  .Mui-selected {
    background-color: ${colors.softGray} !important;
  }

  .MuiPaper-root  {
    margin-top: 15px !important
  }

  .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-outlined{
    line-height: 16px;
  }

  
  .chip-list {
    display: flex;
    flex-wrap: wrap;
    margin-left: 10px;
  }
  .chip {
    display: flex;
    align-items: center;
    background-color: #e0e0e0;
    padding: 4px 8px;
    border-radius: 16px;
    margin-right: 8px;
    margin-top: 8px;
    font-size: 14px;
  }
  .chip-remove {
    margin-left: 8px;
    cursor: pointer;
    font-weight: bold;
  }
  .rs-btn-close 
  .rs-icon {
    width: 9px;
  }
  .rs-tag {
    color: ${colors.bodyColor};
    background-color: ${colors.softGray}
  }
  .rs-dropdown-toggle-caret {
    font-size: 10px;
  }
  
  .rs-dropdown-item {
    text-transform: capitalize;
  }

  .rs-dropdown-item:hover,
  .rs-dropdown-item:hover > 
  .rs-dropdown-item-toggle {
    color: ${({ theme }) => theme.themes?.colorPrimary};
    background-color: ${colors.softGray}
  }

  .rs-tag + .rs-tag {
    margin-left: 0;
  }
  
  .rs-tag {
    margin-right: 8px;
    margin-bottom: 5px;
  }
  
  .rs-checkbox-checked .rs-checkbox-wrapper .rs-checkbox-inner::before, 
  .rs-checkbox-indeterminate .rs-checkbox-wrapper .rs-checkbox-inner::before {
    border-color: ${({ theme }) => theme.themes?.colorPrimary} !important;
    background-color:  ${({ theme }) => theme.themes?.colorPrimary} !important;
  }
  .rs-checkbox-wrapper::before,
  label:hover .rs-checkbox-wrapper .rs-checkbox-inner::before {
    border-color: ${({ theme }) => theme.themes?.colorPrimary} !important;
  }
  .Item_item__azkIp:hover {
    color: #172B4D !important;
  } 
  
  .row-flex-end {
    justify-content: flex-end !important;
  }
  
  .last-row {
    padding-right: 25px !important;
  }
  
  .testttt button {
    background-color: transparent !important;
    box-shadow: none !important;
  }
  
  .testttt > div {
    left: 100% !important;
    top: 0 !important;
  }
  
  .button-no-padd button {
    padding: 0 !important;
    font-weight: 400 !important;
    justify-content: flex-start !important;
  }
  
  .button-no-padd > div {
    min-width: auto !important;
  }

  .rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
    box-shadow: none !important;
  }

  .rs-calendar-table-cell-in-range::before {
    background-color: ${colors.softGray};
  }
  
  //Not hover table row//
  .not-hover .MuiDataGrid-row:hover,
  .MuiDataGrid-row.Mui-hovered,
  .not-hover .Mui-selected {
    background-color: transparent !important;
  }
  
  .not-hover .MuiDataGrid-cell {
    cursor: auto !important;
  }

  .rs-calendar-month-dropdown-row-wrapper {
    &::-webkit-scrollbar {
      width:  6px;
      height: 10px;
      background: ${colors.bodyColor};
      border-radius: 4px;
      --webkit-overflow-scrolling: touch;
      &-thumb {
        border-radius: 4px;
      }
      &-track {
        background-color: white;
      }
    }

    &:hover {
      scrollbar-color: colors;

      &::-webkit-scrollbar-thumb {
        background-color: ${colors.bodyColor};
      }

      &::-webkit-scrollbar-track {
        background-color: white;
      }
    }

    /* Mozilla Scrollbar */
    scrollbar-width: thin;
    scrollbar-color: #fff #fff;
  }

  .MuiToolip-popperInteractive{
    background-color: red;
  }
  
  .delete_action{
    margin: 8px 0;
    span{
      border-radius: 0;
      border-top: 1px solid #F5F6F8;
      width: 231px;
      top: -3px;
      left: -5px;
    }
  }
  
  .menu-item{
    margin-bottom: 8px !important;
  .not-highlight .MuiDataGrid-main .MuiDataGrid-row:hover,
  .not-highlight .MuiDataGrid-main .MuiDataGrid-row.Mui-hovered {
    background-color: transparent !important;
  }  
  
  .not-highlight .Mui-selected{
    background-color: transparent !important;
  }
  
  .not-highlight .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)>.MuiDataGrid-cell {
    cursor: initial !important;
  }
  }
  .MuiDataGrid-cell .MuiTooltip-popper {
    position: fixed !important;
  }  
  .MuiDataGrid-cell .MuiTooltip-tooltip.MuiTooltip-tooltipPlacementBottom  {
    margin-top: 2px !important;
  }

  .rs-tag-icon-close{
    min-width: 25px !important;
  }
  
  .white-forminput input{
    background-color: white;
    &:disabled{
      background-color: ${colors.lightGray};
    }
  }
  .white-forminput label{
    transition: none;
    z-index: 3;
  }

  .white-forminput.mrg-top{
    margin-top: 10px;
  }

  .input-wrapper-disabled.white-forminput label{
    background-color: ${colors.lightGray};
    cursor: not-allowed;
    transition: none;
  }

  .rs-picker-error .rs-input-group{
    border-color: #f1f1f1 !important;
  }
  .rs-input-group:focus-within{
    border-color: #f1f1f1 !important;
    outline: none !important;
    outline-width: 0px !important;
  }
  
  .rs-picker-error>.rs-input-group:focus-within{
    border-color: #f1f1f1 !important;
    outline: none !important;
    outline-width: 0px !important;
  }
`;
