import React from 'react';
import PropTypes from 'prop-types';

import Box from '../../../../common/box/Box';
import { RadioButton } from '../../../../common/radio-button';

import { Title, Description } from './styles';

export default function SingleOption({
  id,
  checked,
  onChange,
  title,
  description
}) {
  return (
    <Box mt={'20px'}>
      <Box display={'flex'} alignitems={'center'} gap={'5px'}>
        <Box ml={'-3px'}>
          <RadioButton id={id} checked={checked} onChange={onChange} />
        </Box>
        <Title htmlFor={id}>{title}</Title>
      </Box>
      <Description>{description}</Description>
    </Box>
  );
}

SingleOption.propTypes = {
  id: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired
};
