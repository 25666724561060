import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import Text from '../../../common/text/Text';
import CloseModalIcon from '../../../../assets/icons/x.svg';
import {
  CloseModalImg,
  ModalBasic,
  ModalBody,
  ModalFooter,
  ModalHeader
} from '../../../common/modal/Modal';
import Title from '../../../common/title/Title';
import Button from '../../../common/button/Button';
import hasPermission from '../../../../security/Permission';
import { updateMemberMfa } from '../../endpoints';
import Box from '../../../common/box/Box';
import Input from '../../../common/input/Input';

export default function MfaModalDisabledMember({ text, open, close, id }) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [writtenText, setWrittenText] = useState('');
  const [error, setError] = useState('');
  const canUpdateMfaPerm = hasPermission('oem_profile', 'update_mfa');
  const onChange = e => {
    setWrittenText(e.target.value);
  };

  useEffect(() => {
    if (text !== writtenText) {
      setError(t('textMismatchError'));
    } else {
      setError('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [writtenText]);

  useEffect(() => {
    if (!open) {
      setWrittenText('');
    }
  }, [open]);

  const handleDisableMfa = () => {
    setIsLoading(true);
    updateMemberMfa(id, {
      enabled: false
    }).then(([res]) => {
      close({ success: res.success, message: res?.code || res?.Code });
      setIsLoading(false);
    });
  };

  if (!canUpdateMfaPerm) return null;
  else {
    return (
      <ModalBasic
        style={{
          width: '450px',
          overflowY: 'auto',
          maxHeight: '-webkit-fill-available'
        }}
        open={open}
        closeModal={close}
      >
        <div>
          <ModalHeader>
            <Title fontSize="16px" fontWeight="500">
              {t('disableMultiFactorAuthentication')}
            </Title>
            <CloseModalImg src={CloseModalIcon} onClick={close} />
          </ModalHeader>

          <ModalBody mb="30px">
            <Text fontSize="14px" mb="15px" maxwidth="490px" textAlign="left">
              {t('disableMfaWarning')}
            </Text>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              w="100%"
            >
              <Text left="true" fontSize="14px" fontWeight="400">
                {t('typeToConfirm', {
                  text: text
                })}
              </Text>
            </Box>
          </ModalBody>

          <ModalFooter display="flex" gap={'10px'} mt="30px">
            <Input
              marginBottom="0"
              width={'100%'}
              borderColor="#F5F6F8"
              fontSize="14px"
              value={writtenText}
              onChange={onChange.bind(this)}
            />
            <Button
              w="108px"
              isLoading={isLoading}
              disabled={text.length === 0 || error.length > 0}
              onClick={handleDisableMfa}
              primary
            >
              {t('disable')}
            </Button>
          </ModalFooter>
        </div>
      </ModalBasic>
    );
  }
}

MfaModalDisabledMember.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired
};
