import React, { useEffect, useState } from 'react';
import TableHeader from './components/TableHeader';
import Table from './components/Table';
import { colors } from '../../constant';
import { noResultComp } from './helpers';
import styled from 'styled-components';
import { GridToolbarQuickFilter } from '@mui/x-data-grid';
import MultiFilter from '../multi-filter/MultiFilter';
import { useTranslation } from 'react-i18next';

const TableComponent = ({
  columns,
  rows,
  onPageChange,
  onSizeChange,
  size,
  page,
  totalSize,
  searchValue,
  searchOnChange,
  dateOnChange,
  dateValue,
  cascaderOptions,
  cascaderOnChange,
  exportOnClick,
  cleanable,
  display,
  searchable,
  titleTable,
  title,
  tooltip,
  onRowClick,
  filters,
  filterOnChange,
  filterWith,
  disabledDate,
  components,
  displayLoader,
  className,
  hideFooter,
  lastSync
}) => {
  const { t } = useTranslation();

  const localizedTextsMap = {
    columnMenuUnsort: t('unsort'),
    columnMenuSortAsc: t('columnMenuSortAsc'),
    columnMenuSortDesc: t('columnMenuSortDesc'),
    columnMenuFilter: t('columnMenuFilter'),
    columnMenuHideColumn: t('columnMenuHideColumn'),
    columnMenuShowColumns: t('columnMenuShowColumns'),
    filterOperatorContains: t('filterOperatorContains'),
    filterOperatorEquals: t('filterOperatorEquals'),
    filterOperatorStartsWith: t('filterOperatorStartsWith'),
    filterOperatorEndsWith: t('filterOperatorEndsWith'),
    filterOperatorIs: t('filterOperatorIs'),
    filterOperatorNot: t('filterOperatorNot'),
    filterOperatorAfter: t('filterOperatorAfter'),
    filterOperatorOnOrAfter: t('filterOperatorOnOrAfter'),
    filterOperatorBefore: t('filterOperatorBefore'),
    filterOperatorOnOrBefore: t('filterOperatorOnOrBefore'),
    filterOperatorIsEmpty: t('filterOperatorIsEmpty'),
    filterOperatorIsNotEmpty: t('filterOperatorIsNotEmpty'),
    filterOperatorIsAnyOf: t('filterOperatorIsAnyOf'),
    filterPanelInputLabel: t('value'),
    filterPanelInputPlaceholder: t('filterValue'),
    filterPanelColumns: t('columns'),
    columnsPanelTextFieldLabel: t('findColumn'),
    columnsPanelTextFieldPlaceholder: t('columnTitle'),
    filterPanelOperators: t('operator'),
    columnsPanelHideAllButton: t('hideAll'),
    columnsPanelShowAllButton: t('showAll'),
    columnHeaderSortIconLabel: t('sort'),
    checkboxSelectionHeaderName: t('checkboxSelectionHeaderName')
  };
  const [searchFocus, setSearchFocus] = useState(false);

  useEffect(() => {
    setSearchFocus(true);
    return () => setSearchFocus(false);
  }, [searchValue]);

  useEffect(() => {
    setSearchFocus(false);
  }, []);

  const [selectedFilters, setSelectedFilters] = useState([]);

  const QuickSearchToolbar = () => (
    <TableHeader
      dateValue={dateValue}
      dateOnChange={dateOnChange}
      exportOnClick={exportOnClick}
      cascaderOptions={cascaderOptions}
      cascaderOnChange={cascaderOnChange}
      cleanable={cleanable}
      display={display}
      searchable={searchable}
      titleTable={titleTable}
      title={title}
      tooltip={tooltip}
      filters={filters}
      filterOnChange={filterOnChange}
      filterWith={filterWith}
      disabledDate={disabledDate}
      lastSync={lastSync}
      multiFilter={
        <MultiFilter
          setSelectedFilters={setSelectedFilters}
          selectedFilters={selectedFilters}
          filters={filters}
          filterOnChange={filterOnChange}
          filterWith={filterWith}
        />
      }
      searchComponent={
        <Search
          value={searchValue}
          onChange={e => searchOnChange(e.target.value)}
          InputProps={{
            disableUnderline: true,
            autoFocus: searchFocus,
            placeholder: t('search')
          }}
          // quickFilterFormatter={() => searchValue}
          // quickFilterParser={(searchInput) => {
          //     searchOnChange(searchInput);
          // }
          // }
          // debounceMs={500}
        />
      }
    />
  );
  return (
    <div>
      <Table
        localeText={localizedTextsMap}
        className={className}
        columns={columns}
        size={size}
        rows={rows}
        onPageChange={onPageChange}
        onSizeChange={onSizeChange}
        pageSize={size}
        totalSize={totalSize}
        page={page}
        onRowClick={onRowClick}
        components={{
          Toolbar: QuickSearchToolbar,
          ...noResultComp,
          ...components
        }}
        displayLoader={displayLoader}
        hideFooter={hideFooter}
      />
    </div>
  );
};

const Search = styled(GridToolbarQuickFilter)`
  background-color: ${colors.softGray};
  border-radius: 4px;

  &.MuiTextField-root {
    padding: 4px 10px;
    margin-left: 0;

    input::placeholder {
      color: ${colors.gray20};
      opacity: 1;
      font-size: 14px;
    }
  }
`;

export default TableComponent;
