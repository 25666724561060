/* eslint-disable react/prop-types */
/* eslint-disable no-restricted-globals */
import React, { useEffect, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GridToolbarQuickFilter } from '@mui/x-data-grid';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { ActionMenuItem, Table } from '../common/table/Table';
import { createBucket, deleteBucket, getAllBuckets } from './endpoints';
import { DeleteBucketModal } from './delete/DeleteBucketModal';
import { renderCellExpand } from '../common/table/TableCellExpand';
import { formatDateWithUtc } from '../../utils/format.dates.utils';
import { CustomPagination } from '../common/pagination/Pagination';
import { LoaderWrap } from '../common/loader/Loader';
import { colors } from '../constant';
import { showToast } from '../../hooks/show-toast';
import Box from '../common/box/Box';
import Title from '../common/title/Title';
import Icon from '../common/icon/Icon';
import hasPermission from '../../security/Permission';
import Button from '../common/button/Button';
import Text from '../common/text/Text';

import CreateBucketModal from './create/CreateBucketModal';
import { EmptyBucketsView } from './EmptyBucketsView';
import { getFlagUrl, localizedTextsMap } from './helpers';

export function BucketsList() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userData } = useStoreState(state => state.user);
  const { setAuthLoader } = useStoreActions(actions => actions.authentication);
  const [response, setResponse] = useState(null);
  const [displayLoader, setDisplayLoader] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchValue, setSearchValue] = useState('');
  const [searchFocus, setSearchFocus] = useState(false);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    Name: true,
    Region: true,
    CreationDate: true,
    actions: true
  });
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  useEffect(() => {
    setSearchFocus(true);
  }, [searchValue]);

  function QuickSearchToolbar() {
    return (
      <Box mb="20px" fontSize="14px">
        <Search
          placeholder={t('search')}
          InputProps={{ disableUnderline: true, autoFocus: searchFocus }}
          quickFilterFormatter={() => searchValue}
          quickFilterParser={searchInput => {
            setSearchValue(searchInput);
            setCurrentPage(0);
            return searchInput
              .split(',')
              .map(value => value.trim())
              .filter(value => value !== '');
          }}
          debounceMs={1000}
        />
      </Box>
    );
  }

  const canReadBucketsPerm = hasPermission('oem_buckets', 'read_all');
  const canCreateBucketsPerm = hasPermission('oem_buckets', 'create');
  const canDeleteBucketsPerm = hasPermission('oem_buckets', 'delete');
  const canReadBucketDetailsPerm = hasPermission('oem_buckets', 'read');

  const fetchBuckets = () => {
    setDisplayLoader(true);
    getAllBuckets().then(([res]) => {
      setDisplayLoader(false);
      if (res?.success) {
        setResponse(res.data ?? []);
      } else {
        if (res?.code || res?.Code !== 'ErrCcc0045')
          showToast(
            {
              message: res?.code || res?.Code,
              params: res?.params || null,
              success: false
            },
            'error'
          );
      }
    });
  };

  useEffect(() => {
    if (userData && userData.Id) {
      fetchBuckets();
      setSearchFocus(false);
    }
  }, [userData]);

  useEffect(() => {}, [currentPage]);

  useEffect(() => {
    window.scrollTo(0, 0);
    setAuthLoader(false);
  }, [setAuthLoader]);

  /**
   * Create new bucket.
   */
  const [showCreateBucket, setShowCreateBucket] = useState(false);
  const onCreateBucketClick = useCallback(
    () => () => {
      setShowCreateBucket(true);
    },
    []
  );

  /**
   * Delete bucket.
   */
  const [showDeleteBucket, setShowDeleteBucket] = useState(false);
  const [bucketToDelete, setBucketToDelete] = useState('');
  const onDeleteClick = useCallback(
    name => () => {
      setShowDeleteBucket(true);
      setBucketToDelete(name);
    },
    []
  );
  const handleDeleteBucket = props => {
    setDisplayLoader(true);
    setIsButtonLoading(true);
    deleteBucket(props.name).then(([res]) => {
      setDisplayLoader(false);
      if (res.success) {
        setShowDeleteBucket(false);
        fetchBuckets();
        showToast(
          {
            message: res?.code || res?.Code,
            params: res?.params || null,
            success: res?.success
          },
          res?.success ? 'success' : 'error'
        );
      } else {
        showToast(
          {
            message: res?.code || res?.Code,
            params: res?.params || null,
            success: false
          },
          'error'
        );
      }
      setIsButtonLoading(false);
    });
  };

  const handleCreateBucket = props => {
    setDisplayLoader(true);
    createBucket(props).then(([res]) => {
      setDisplayLoader(false);
      setCurrentPage(0);
      if (res.success) {
        setShowCreateBucket(false);
        fetchBuckets();
        showToast(
          {
            message: res?.code || res?.Code,
            params: res?.params || null,
            success: res?.success
          },
          res?.success ? 'success' : 'error'
        );
      } else {
        showToast(
          {
            message: res?.code || res?.Code,
            params: res?.params || null,
            success: false
          },
          'error'
        );
      }
    });
  };
  const columns = [
    {
      field: 'Name',
      headerName: t('bucket'),
      flex: 1,
      renderCell: params =>
        renderCellExpand({
          value: params.row.Name,
          width: params,
          fontWeight: '500',
          iconName: 'bucket',
          iconMinWidth: '24px'
        })
    },
    {
      field: 'Region',
      headerName: t('region'),
      flex: 1,
      valueGetter: params =>
        `${params?.row?.Region?.location} (${params?.row?.Region?.slug})`,
      renderCell: params => renderRegion(params)
    },
    {
      field: 'CreationDate',
      headerName: t('creationTime'),
      flex: 1,
      renderCell: params => formatDateWithUtc(params.row?.CreationDate),
      sortComparator: (v1, v2, param1, param2) =>
        new Date(param1.api.getCellValue(param1.id, 'CreationDate')) -
        new Date(param2.api.getCellValue(param2.id, 'CreationDate'))
    },
    {
      field: t('actions'),
      type: 'actions',
      width: 50,
      cellClassName: 'right-side',
      getActions: params => [
        <ActionMenuItem
          key="view"
          label={t('view')}
          onClick={() =>
            navigate('/buckets/'.concat(params.id).concat('/objects'))
          }
          showInMenu={canReadBucketDetailsPerm}
          icon={
            canReadBucketDetailsPerm ? (
              <Icon
                width="24px"
                height="19px"
                mr="10px"
                icon="bucketIcon"
                color={colors.gray}
              />
            ) : (
              <></>
            )
          }
        />,
        <ActionMenuItem
          key="delete"
          label={t('delete')}
          onClick={onDeleteClick(params.id)}
          showInMenu={canDeleteBucketsPerm}
          sx={{ color: colors.red }}
          icon={
            canDeleteBucketsPerm ? (
              <Icon
                height="19px"
                mr="10px"
                icon="deleteFilled"
                color={colors.red}
              />
            ) : (
              <></>
            )
          }
        />
      ]
    }
  ];

  const renderRegion = props => {
    return (
      <Box display="flex" alignitems="center" justifycontent="center">
        {props.row?.Region?.region && (
          <Box display="inline-flex">
            <RegionFlag
              src={require(`../../assets/flags/regions/${getFlagUrl(
                props.row?.Region?.region
              )}.svg`)}
              alt="Region flag"
            />
          </Box>
        )}
        <RegionText maxWidth={`calc(${props.colDef?.computedWidth}px - 50px)`}>
          {renderCellExpand({
            value: `${props.row?.Region?.location} (${props.row?.Region?.slug})`,
            width: (props.colDef?.computedWidth || 0).toFixed(0) - 40,
            color: '#525E6B',
            fontWeight: '400'
          })}
        </RegionText>
      </Box>
    );
  };

  return (
    <>
      {canReadBucketsPerm && (
        <Box p="30px" bgcolor={colors.softGray}>
          <Box p="30px" bgcolor={colors.white}>
            <Box
              display="flex"
              alignitems="center"
              justifycontent="space-between"
            >
              <Title color={colors.black} fontWeight="500" fontSize="18px">
                {t('buckets')}
              </Title>
              {response?.length > 0 && canCreateBucketsPerm && (
                <Button
                  primary
                  minHeight="40px"
                  onClick={onCreateBucketClick()}
                >
                  {t('createBucket')}
                </Button>
              )}
            </Box>

            <BorderBottom />

            <Box relative="true">
              {response ? (
                response.length > 0 ? (
                  <div style={{ width: '100%' }}>
                    <Table
                      localeText={localizedTextsMap}
                      autoHeight
                      rows={response}
                      columns={columns}
                      page={currentPage}
                      onPageChange={newPage => setCurrentPage(newPage)}
                      pageSize={20}
                      pagination
                      rowsPerPageOptions={[20, 50, 100]}
                      getRowId={row => row.Name}
                      components={{
                        Pagination: CustomPagination,
                        Toolbar: QuickSearchToolbar,
                        NoResultsOverlay: () => (
                          <Text
                            fontSize="14px"
                            mt="10px"
                            color={colors.bodyColor}
                          >
                            {t('thereIsCurrentlyNoData')}
                          </Text>
                        )
                      }}
                      onRowClick={(params, event) => {
                        if (!event.ignore) {
                          navigate(
                            '/buckets/'.concat(params.id).concat('/objects')
                          );
                          // navigate(0);
                        }
                      }}
                      columnVisibilityModel={columnVisibilityModel}
                      onColumnVisibilityModelChange={newModel =>
                        setColumnVisibilityModel(newModel)
                      }
                    />
                  </div>
                ) : (
                  <>
                    {canCreateBucketsPerm && (
                      <EmptyBucketsView
                        onCreateBucketClick={onCreateBucketClick()}
                      />
                    )}
                  </>
                )
              ) : (
                <Box p="20px 0">
                  <LoaderWrap display={displayLoader} />
                </Box>
              )}
            </Box>

            <CreateBucketModal
              displayLoader={displayLoader}
              open={showCreateBucket}
              close={() => setShowCreateBucket(false)}
              onSubmit={handleCreateBucket}
              modalRendered={showCreateBucket}
            />
            <DeleteBucketModal
              isLoading={isButtonLoading}
              open={showDeleteBucket}
              close={() => setShowDeleteBucket(false)}
              name={bucketToDelete}
              onSubmit={e => handleDeleteBucket(e)}
            />
          </Box>
        </Box>
      )}
      {localStorage.getItem('refreshTokenExpired') && !canReadBucketsPerm ? (
        <LoaderWrap display={true} />
      ) : (
        <>{!canReadBucketsPerm && <Box>{t('noPermissionForThisRoute')}</Box>}</>
      )}
    </>
  );
}

const BorderBottom = styled.div`
  border-bottom: 1px solid #f1f1f1;
  margin: 20px 0;
`;

const Search = styled(GridToolbarQuickFilter)`
  background-color: ${colors.softGray};
  border-radius: 4px;

  &.MuiTextField-root {
    padding: 4px 10px;
    margin-left: 0;

    input::placeholder {
      color: ${colors.gray20};
      opacity: 1;
      font-size: 14px;
    }
  }
`;

const RegionFlag = styled.img`
  object-fit: cover;
  width: 19px;
  height: 13px;
  display: inline-flex;
  margin-right: 10px;
`;

const RegionText = styled(Box)`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #525e6b;
  max-width: ${props =>
    props.maxWidth ||
    '400px'}; /* Set the max-width based on the maxWidth prop */
`;
