/* eslint-disable no-useless-escape */
/* eslint-disable no-restricted-globals */
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Box from '../../../common/box/Box';
import Icon from '../../../common/icon/Icon';
import Line from '../../../common/line/Line';
import Title from '../../../common/title/Title';
import Button from '../../../common/button/Button';
import hasPermission from '../../../../security/Permission';
import Input, { ErrorMessage } from '../../../common/input/Input';
import ChangePasswordNotAvailable from '../../../common/change-password-not-available';
import { colors } from '../../../constant';
import { changePassword } from '../../../auth/endpoints';
import { showToast } from '../../../../hooks/show-toast';

const ChangePassword = () => {
  const { t } = useTranslation();
  const canChangePasswordPerm = hasPermission('oem_profile', 'change_password');
  const isSsoUser = JSON.parse(localStorage.getItem('userIsSso'));
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset
  } = useForm({ mode: 'onChange' });

  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordVerified, setPasswordVerified] = useState(false);
  const [displayLoader, setDisplayLoader] = useState(false);

  const onOldPasswordChange = e => {
    setOldPassword(e.target.value);
  };

  const onNewPasswordChange = e => {
    setNewPassword(e.target.value);
    setPasswordVerified(
      confirmPassword.length > 0 && e.target.value === confirmPassword
    );
  };

  const onConfirmPasswordChange = e => {
    setConfirmPassword(e.target.value);
    setPasswordVerified(
      newPassword.length > 0 && e.target.value === newPassword
    );
  };

  const handleFromSubmit = () => {
    setDisplayLoader(true);

    if (oldPassword.length > 0 && passwordVerified) {
      changePassword(oldPassword, newPassword, false).then(([res]) => {
        if (res.success) {
          setOldPassword('');
          setNewPassword('');
          setConfirmPassword('');
          reset();
        }
        setDisplayLoader(false);
        showToast(
          {
            message: res?.code || res?.Code,
            params: res?.params || null,
            success: res?.success
          },
          res?.success ? 'success' : 'error'
        );
      });
    }
  };

  return (
    <>
      <Box relative="true" bgcolor={colors.white} p="30px" mt="30px">
        <Box display="flex" alignitems="center" justifycontent="space-between">
          <Title fontSize="18px" fontWeight="500" color={colors.black}>
            {t('changePassword')}
          </Title>
        </Box>

        <Line m="20px 0 40px" />
        {isSsoUser ? (
          <ChangePasswordNotAvailable />
        ) : (
          <form onSubmit={handleSubmit(handleFromSubmit)}>
            <div
              className={`input-wrapper ${
                isSsoUser ? 'input-wrapper-disabled' : ''
              }`}
            >
              <Input
                size="0"
                disabled={isSsoUser}
                width="100%"
                fontSize="14px"
                name="oldPassword"
                type="password"
                marginBottom="30px"
                className={errors.oldPassword ? 'not-valid' : ''}
                {...register('oldPassword', {
                  onChange: onOldPasswordChange,
                  required: true
                })}
              />

              <label
                htmlFor="oldPassword"
                className={
                  oldPassword.length > 0 ? 'filledField' : 'input-label'
                }
              >
                {t('oldPassword')}*
              </label>
              {errors.oldPassword?.type === 'required' && (
                <ErrorMessage>{t('oldPasswordRequired')}</ErrorMessage>
              )}
            </div>

            <div
              className={`input-wrapper ${
                isSsoUser ? 'input-wrapper-disabled' : ''
              }`}
            >
              <Input
                size="0"
                disabled={isSsoUser}
                width="100%"
                fontSize="14px"
                name="newPassword"
                type="password"
                marginBottom="30px"
                className={errors.newPassword ? 'not-valid' : ''}
                {...register('newPassword', {
                  onChange: onNewPasswordChange,
                  required: true,
                  minLength: 5,
                  pattern: {
                    value: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\" \!\"\#\$\%\&\'\(\)\*\+\,\-\.\/\:\;\<\=\>\?\@\[\\\]\^\_\`\{\|\}\~\"]).{6,}/,
                    message: t('newPasswordPattern')
                  }
                })}
              />

              <label
                htmlFor="newPassword"
                className={
                  newPassword.length > 0 ? 'filledField' : 'input-label'
                }
              >
                {t('newPassword')}*
              </label>
              {errors.newPassword?.type === 'minLength' && (
                <ErrorMessage>{t('newPasswordMinLength')}</ErrorMessage>
              )}
              {errors.newPassword?.type === 'pattern' && (
                <ErrorMessage>{t('newPasswordPattern')}</ErrorMessage>
              )}
              {errors.newPassword?.type === 'required' && (
                <ErrorMessage>{t('newPasswordRequired')}</ErrorMessage>
              )}
            </div>

            <div
              className={`input-wrapper ${
                isSsoUser ? 'input-wrapper-disabled' : ''
              }`}
            >
              <Input
                size="0"
                disabled={isSsoUser}
                width="100%"
                fontSize="14px"
                name="confirmPassword"
                type="password"
                marginBottom="0"
                padding="0 35px 0 10px"
                className={
                  !passwordVerified && confirmPassword.length > 0
                    ? 'not-valid'
                    : ''
                }
                {...register('confirmPassword', {
                  onChange: onConfirmPasswordChange,
                  required: true
                })}
              />

              <label
                htmlFor="confirmPassword"
                className={
                  confirmPassword.length > 0 ? 'filledField' : 'input-label'
                }
              >
                {t('confirmPassword')}*
              </label>
              <Icon
                icon="verify"
                position="absolute"
                right="10px"
                top="12px"
                display={passwordVerified && isValid ? 'block' : 'none'}
              />
              {!passwordVerified && confirmPassword.length > 0 && (
                <ErrorMessage className="error-message--nospace">
                  {t('passwordsMustMatch')}
                </ErrorMessage>
              )}
            </div>
            {canChangePasswordPerm && (
              <Box end="true" mt="30px">
                <Button
                  w="136px"
                  isLoading={displayLoader}
                  disabled={
                    oldPassword.length === 0 || !passwordVerified || !isValid
                  }
                  type={'submit'}
                  primary
                >
                  {t('update')}
                </Button>
              </Box>
            )}
          </form>
        )}
      </Box>
    </>
  );
};

export default ChangePassword;
