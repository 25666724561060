import styled from 'styled-components';
import Select from '@mui/material/Select';

export const Dropdown = styled(Select).attrs(({ sx }) => ({
  sx: {
    '.MuiOutlinedInput-input.MuiInputBase-readOnly': {
      backgroundColor: '#F5F6F8',
      cursor: 'initial'
    },
    '.MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input': {
      minHeight: '40px',
      padding: '0 35px 0 10px',
      alignItems: 'center'
    },
    '.MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input .region__slug': {
      maxWidth: '140px !important'
    },
    '.MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input .region__slug__long': {
      maxWidth: '110px !important'
    },
    '.MuiOutlinedInput-notchedOutline': {
      borderColor: '#F1F1F1 !important',
      borderWidth: 1
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: '#F1F1F1 !important',
      borderWidth: ''
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#F1F1F1',
      borderWidth: 1
    },
    '.Mui-disabled': {
      cursor: 'not-allowed'
    },
    '&.not-valid .MuiOutlinedInput-notchedOutline': {
      borderColor: 'red',

      '&:hover': {
        borderColor: 'red'
      }
    }
  }
}))``;
